import React from 'react'
import { IRS_CORNER } from '../../components/constant'
import Layout from '../../components/layout'
import PageStructure from '../../components/pagestructure'
import SEO from '../../components/seo'
import IMGHobbies from '../../images/hobbies.jpg'
const Hobbies = () => {
    return (
        <div>
            <Layout>
                <SEO title="Hobbies" />
                <PageStructure
                    image={IMGHobbies}
                    title="Hobbies"
                    desc="Hobbies"
                    mode={IRS_CORNER}
                />
            </Layout>
        </div>
    )
}

export default Hobbies
